function InitialTip() {
  return (
    <div>
      <a
        href="https://github.com/johnjacobkenny/cowin-checker#district-id-lookup"
        target="_blank"
      >
        Click here to find your district ID
      </a>
    </div>
  );
}

export default InitialTip;
