function UsageTip() {
  return (
    <div>
      <hr />
      You can copy this URL and share with your friends
    </div>
  );
}

export default UsageTip;
